'use client'

import { cn } from '@/app/_primitives/utils/cn'
import React, {
  ChangeEvent,
  ForwardedRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

type Props = {
  size?: 'sm' | 'lg'
  error?: boolean
  variant?: 'default' | 'dark'
  containerClassName?: string
  errorMsgs?: string
  isTel?: boolean
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  disableCheckValidity?: boolean
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>

function TextFieldComponent(
  {
    className,
    containerClassName,
    error = false,
    size = 'sm',
    variant = 'default',
    errorMsgs,
    startAdornment,
    endAdornment,
    isTel,
    disableCheckValidity = false,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [err, setError] = useState(error)
  const inputRef = useRef<HTMLInputElement>(null)
  useImperativeHandle(ref, () => inputRef.current!)
  useEffect(() => {
    const inputElement = inputRef.current

    if (!inputElement) {
      return
    }
    const handleInvalid = (event: Event) => {
      event.preventDefault()
      setError(true)
    }

    inputElement.addEventListener('invalid', handleInvalid)

    return () => {
      inputElement.removeEventListener('invalid', handleInvalid)
    }
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputElement = inputRef.current
    if (!inputElement) {
      return
    }

    // 2025.03.11 Add Tel Regex by dongmin
    if (isTel) {
      const filteredValue = e.target.value.replace(/[^0-9-]/g, '')
      if (e.target.value !== filteredValue) {
        e.target.value = filteredValue
      }
    }

    if (disableCheckValidity) {
      props.onChange?.(e)
      return
    }

    setError(!inputElement.checkValidity())
    props.onChange?.(e)
  }

  return (
    <div className="flex flex-col gap-8 self-stretch">
      <div
        className={cn(
          'flex flex-row items-stretch justify-between',
          'rounded-15 border border-[#D9DADB] bg-[#FFFFFFCC]',
          'ring-[#1D7AFC] focus-within:ring-2',
          {
            'max-h-60': size === 'lg',
            'max-h-48': size === 'sm',
            'border-[#FF9840]': (err || error) && variant === 'default',
            'border-[#FF2B2B] ring-[#FFFFFF] focus-within:ring-2':
              (err || error) && variant === 'dark',
          },
          containerClassName,
        )}>
        {startAdornment}
        <input
          ref={inputRef}
          required
          className={cn(
            'flex-1',
            'w-full rounded-15 px-20 focus:outline-none focus-visible:outline-none',
            'bg-[#FFFFFFCC] placeholder:text-[#8B8B8B]',
            'disabled:cursor-not-allowed disabled:text-[#8B8B8B]',
            {
              'c-b3 py-17': size === 'lg',
              'c-b4 py-13': size === 'sm',
            },
            className,
          )}
          {...props}
          onChange={handleChange}
        />
        {endAdornment}
      </div>
      {(err || error) && errorMsgs && (
        <p className="c-b4 text-[#FF2B2B]">{errorMsgs}</p>
      )}
    </div>
  )
}

export const TextField = React.forwardRef<HTMLInputElement, Props>(
  TextFieldComponent,
)

TextField.displayName = 'TextField'
