import { Button } from '@/app/_primitives/Button'
import { Calendar } from '@/app/_primitives/Calendar'
import { Dialog, DialogContent, DialogTrigger } from '@/app/_primitives/Dialog'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/app/_primitives/Popover'
import { UpDownControl } from '@/app/_primitives/UpDownControl'
import { boundedNumber } from '@/app/_primitives/utils/bounded-num'
import { DatePickerStateProvider } from '@rehookify/datepicker'
import React, { useState } from 'react'

export type DatePickerProps = {
  value?: Date
  onChange: (date: Date) => void
  children: React.ReactNode

  min?: Date
  max?: Date

  mode?: 'date' | 'datetime'
  displayMode?: 'popover' | 'dialog'

  side?: React.ComponentProps<typeof PopoverContent>['side']
  align?: React.ComponentProps<typeof PopoverContent>['align']
}

export function DatePicker({
  value,
  onChange,
  min,
  max,
  children,
  mode,

  side,
  align = 'center',
  displayMode = 'popover',
  minuteInterval = 1,
}: DatePickerProps & {
  minuteInterval?: number
}) {
  const enableMinuteInput = Math.abs(minuteInterval) === 1

  const [open, setOpen] = useState(false)
  const [date, setDate] = useState<Date | null>(() => value || null)
  const [time, setTime] = useState(() => ({
    hour: 0,
    minute: 0,
  }))

  const Root = displayMode === 'popover' ? Popover : Dialog
  const Trigger = displayMode === 'popover' ? PopoverTrigger : DialogTrigger
  const Content = displayMode === 'popover' ? PopoverContent : DialogContent

  const onMinuteAction = (action: 'up' | 'down') => {
    setTime((prev) => {
      const d = new Date()

      d.setSeconds(0)
      d.setHours(prev.hour)
      d.setMinutes(prev.minute)

      d.setMinutes(
        d.getMinutes() +
          (action === 'up'
            ? Math.abs(minuteInterval)
            : -Math.abs(minuteInterval)),
      )

      return {
        ...prev,
        hour: d.getHours(),
        minute: d.getMinutes(),
      }
    })
  }

  const onHourAction = (action: 'up' | 'down') => {
    setTime((prev) => {
      const newHour = action === 'up' ? prev.hour + 1 : prev.hour - 1

      const boundedHour = boundedNumber(newHour, 23, 0)

      return {
        ...prev,
        hour: boundedHour,
      }
    })
  }

  const get12HoursHour = (hour: number) => {
    const h = hour > 12 ? hour - 12 : hour

    return h === 0 ? 12 : h
  }

  return (
    <DatePickerStateProvider
      config={{
        selectedDates: date ? [date] : [],
        onDatesChange: (dates) => {
          setDate(dates[0] || null)

          if (mode === 'date' && dates[0]) {
            // trigger onChange when mode is date & a date is selected
            onChange(dates[0])
          }
        },
        dates: {
          mode: 'single',
          toggle: false,
          minDate: min,
          maxDate: max,
        },
        time: {
          useLocales: true,
        },
        locale: {
          locale: 'ko-KR',
          day: '2-digit',
        },
      }}>
      <Root
        open={open}
        onOpenChange={(openState) => {
          if (openState && value) {
            const timeObj = {
              hour: value.getHours(),
              minute: value.getMinutes(),
            }

            if (!enableMinuteInput) {
              const { minute } = timeObj

              const stepCount = 60 / minuteInterval
              const steps = Array.from(
                { length: stepCount },
                (_, i) => i * minuteInterval,
              )

              const diffs = steps.map((step) => Math.abs(step - minute))
              const minDiff = Math.min(...diffs)
              const minIndex = diffs.indexOf(minDiff)

              timeObj.minute = steps[minIndex]
            }

            setTime(timeObj)
          }

          setOpen(openState)
        }}>
        <Trigger asChild>{children}</Trigger>
        <Content
          {...(displayMode === 'popover' && {
            side,
            align,
          })}
          className={
            'flex w-fit flex-col items-stretch gap-20 rounded-20 border border-[#CBD5E1] p-24 lg:flex-row lg:gap-35'
          }>
          <Calendar />
          {mode === 'datetime' && (
            <div
              className={
                'flex flex-col items-center justify-between gap-20 lg:gap-0'
              }>
              <p className={'c-b2 hidden lg:block'}>시간</p>
              <div className={'flex w-191 flex-row items-center gap-24'}>
                <p className="c-h4 text-[#8b8b8b]">
                  {time.hour >= 12 ? 'PM' : 'AM'}
                </p>
                <div className={'flex flex-row items-center gap-16'}>
                  <UpDownControl
                    nextControlId="minute"
                    value={get12HoursHour(time.hour)
                      .toString()
                      .padStart(2, '0')}
                    onChange={(changedHour) => {
                      setTime((prev) => ({
                        ...prev,
                        hour: changedHour,
                      }))
                    }}
                    onConfirmOrFocusLost={(changedHour) => {
                      setTime((prev) => ({
                        ...prev,
                        hour: boundedNumber(changedHour, 23, 0),
                      }))
                    }}
                    onAction={onHourAction}
                  />
                  <p className={'c-h4'}>:</p>
                  <UpDownControl
                    id="minute"
                    onChange={
                      enableMinuteInput
                        ? (changedMinute: number) => {
                            setTime((prev) => ({
                              ...prev,
                              minute: changedMinute,
                            }))
                          }
                        : undefined
                    }
                    onConfirmOrFocusLost={(minute) =>
                      setTime({
                        ...time,
                        minute: boundedNumber(minute, 59, 0),
                      })
                    }
                    value={time.minute.toString().padStart(2, '0')}
                    onAction={onMinuteAction}
                  />
                </div>
              </div>
              <Button
                onClick={() => {
                  if (!date) return

                  const updatedDate = new Date(date)

                  updatedDate.setHours(time.hour)
                  updatedDate.setMinutes(time.minute)
                  updatedDate.setSeconds(0)

                  onChange(updatedDate)
                  setOpen(false)
                }}
                type={'button'}
                className={'w-full self-end lg:w-90'}
                variant={'outlined'}
                size={'sm'}>
                확인
              </Button>
            </div>
          )}
        </Content>
      </Root>
    </DatePickerStateProvider>
  )
}
